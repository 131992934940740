import React, {FunctionComponent as FC, useContext} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../components/button";
import Error from "../components/error";
import FAQs from "../components/faqs/faqs";
import HeroArea from "../components/heroarea";
import Loading from "../components/loading";
import Section from "../components/section";
import {FAQContext} from "../provider/faqs";

type FAQProps = {
  location?: any;
};

const Faq: FC<FAQProps> = ({location}) => {
  const {faqs, loading, error} = useContext(FAQContext);
  const intl = useIntl();

  let previousPath = "/";
  if (location && location.state && location.state.previousPath) {
    previousPath = location.state.previousPath;
  }

  if ((loading || !faqs) && !error) {
    return <Loading />;
  } else if (error || !faqs) {
    return <Error error={error} />;
  } else if (faqs) {
    return (
      <>
        <HeroArea />
        <div className="container">
          <Section
            anchorTag="content"
            title={intl.formatMessage({id: "faq"})}
            className="my-5"
            margin={false}
          >
            <FAQs
              location={location}
              faqs={faqs}
              error={error}
              loading={loading}
            />
          </Section>
          <div className="row mb-5">
            <div className="col-12">
              <Button
                previousPath={previousPath}
                goBack
                className="float-right"
              >
                <FormattedMessage id="faq.back" />
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Error error={"Unexpected state"} />;
  }
};

export default Faq;
